import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, forkJoin, map, Observable, switchMap } from 'rxjs';
import { Application } from '../model/Application';
import { HardcodedUserService } from './hardcoded-user.service';
import { ApplicationsPayload } from '../model/api/application/ApplicationsPayload';
import { ApplicationsPayloadContent } from '../model/api/application/ApplicationsPayloadContent';
import { ConfigurationService } from './configuration.service';
import { AppendixFile } from '../model/AppendixFile';
import { Role } from '../model/Role';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private httpClient: HttpClient,
              private configurationService: ConfigurationService,
              private hardcodedUserService: HardcodedUserService) {
  }

  getApplications(): Observable<Application[]> {
    const currentUser = this.hardcodedUserService.getCurrentUser();
    const token = this.hardcodedUserService.getToken();

    if (!currentUser || !token) {
      return EMPTY;
    }

    return this.hardcodedUserService.getCurrentUserWithInformation().pipe(
      switchMap(user => {
        const applicantName = user.personName;

        const headers = new HttpHeaders({'Authorization': 'Bearer ' + token});
        const options = {headers: headers};

        const currentUserRole = this.hardcodedUserService.getCurrentUserRole();

        let urls: string[] = ['/reporting/api/latest/reports?pageSize=99999'];

        if (currentUserRole === Role.APPLICANT) {
          urls = [
            `/reporting/api/latest/reports?pageSize=99999&created_by=${encodeURIComponent(currentUser)}`,
            `/reporting/api/latest/reports?pageSize=99999&applicant_username=${user.username}`,
          ];
          if (user.cvr && user.cvr !== '') {
            urls.push(`/reporting/api/latest/reports?pageSize=99999&cvr=${user.cvr}`);
          }
        } else if (currentUserRole === Role.COORDINATOR || currentUserRole === Role.BOARD_MEMBER) {
          urls = [
            `/reporting/api/latest/reports?pageSize=99999&lag_organization=${this.hardcodedUserService.getCurrentUserCvr()}`,
            `/reporting/api/latest/reports?pageSize=99999&cvr=${this.hardcodedUserService.getCurrentUserCvr()}`,
          ];
        }

        return forkJoin(urls.map(url => this.httpClient.get<ApplicationsPayload>(url, options)))
          .pipe(
            map(applicationPayloads => {
              // Combining the results from multiple API responses into a single array
              const allApplications = applicationPayloads.flatMap(applicationPayload => 
                applicationPayload && applicationPayload.content ? applicationPayload.content : []
              );

              // Removing duplicates based on application ID
              const uniqueApplications = Array.from(new Set(allApplications.map(a => a.id)))
                .map(id => allApplications.find(a => a.id === id))
                .filter((application): application is ApplicationsPayloadContent => application !== undefined);
              
              return uniqueApplications
                .map(applicationPayloadContent => this.toApplication(applicationPayloadContent))
                .filter(application => {
                  if (currentUser
                    && (currentUserRole === Role.COORDINATOR || currentUserRole === Role.BOARD_MEMBER)
                    && application.createdBy !== currentUser
                    && application.status === 'draft') {
                    return false;
                  }
                  return true;
                });
            })
          );
      })
    )
  }

  deleteApplication(application: Application): Observable<any> {
    return this.httpClient.delete('/reporting/api/latest/reports/' + application.uuid);
  }

  private toApplication(applicationPayloadContent: ApplicationsPayloadContent): Application {
    const id = applicationPayloadContent.id;
    const uuid = applicationPayloadContent.report_id;
    const title = applicationPayloadContent.title;
    const caseId = applicationPayloadContent.case_id;
    const applicationType = applicationPayloadContent.dynamic_fields.application_type;
    const lagOrganization = applicationPayloadContent.dynamic_fields.lag_organization;
    const email = applicationPayloadContent.dynamic_fields.email;
    const status = applicationPayloadContent.status;
    const cvr = applicationPayloadContent.dynamic_fields.cvr;
    const companyName = applicationPayloadContent.dynamic_fields.company_name;
    const applicantName = applicationPayloadContent.dynamic_fields.applicant_name;
    const applicantUserName = applicationPayloadContent.dynamic_fields.applicant_username;
    const createdBy = applicationPayloadContent.created_by;

    const files =
      (applicationPayloadContent.dynamic_fields.appendix_list && applicationPayloadContent.dynamic_fields.appendix_list.length)
        ? applicationPayloadContent.dynamic_fields.appendix_list
          .filter(appendixList => appendixList.appendix_file && appendixList.appendix_file.length)
          .map(appendixList =>
            new AppendixFile(
              appendixList.appendix_file[0].id,
              appendixList.appendix_file[0].name,
              appendixList.document_type
            )
          )
        : [];

    const coordinatorFiles =
      (applicationPayloadContent.dynamic_fields.coordinator_appendix_list && applicationPayloadContent.dynamic_fields.coordinator_appendix_list.length)
        ? applicationPayloadContent.dynamic_fields.coordinator_appendix_list
          .filter(coordinatorAppendixList => coordinatorAppendixList.coordinator_appendix_file && coordinatorAppendixList.coordinator_appendix_file.length)
          .map(coordinatorAppendixList =>
            new AppendixFile(
              coordinatorAppendixList.coordinator_appendix_file[0].id,
              coordinatorAppendixList.coordinator_appendix_file[0].name,
              coordinatorAppendixList.coordinator_document_type
            )
          )
        : [];

    return new Application(id, uuid, lagOrganization, title, caseId, applicationType, status, email, cvr, companyName, applicantName, applicantUserName, createdBy, files, coordinatorFiles);
  }

  saveApplication(application: Application): Observable<any> {
    let payload = {
      'report_type': 'application',
      'dynamic_fields': {
        'application_type': application.applicationType,
        'lag_organization': application.lagOrganization,
        'email': application.email,
        'cvr': application.cvr,
        'company_name': application.companyName,
        'applicant_name': application.applicantName,
        'applicant_username': application.applicantUserName,
      },
      'status': application.status,
      'title': application.projectTitle,
      'case_id': application.projectCaseId,
      'created_by': application.createdBy
    }

    if (application.uuid) {
      Object.assign(payload, {'report_id': application.uuid});
    }

    let appendixList: any = [];
    application.files.forEach(file => appendixList.push({
      "appendix_file": [{"name": file.name, "id": file.id}],
      "document_type": file.documentType
    }))
    Object.assign(payload['dynamic_fields'], {'appendix_list': appendixList});

    let coordinatorAppendixList: any = [];
    application.coordinatorFiles.forEach(coordinatorFile => coordinatorAppendixList.push({
      "coordinator_appendix_file": [{"name": coordinatorFile.name, "id": coordinatorFile.id}],
      "coordinator_document_type": coordinatorFile.documentType
    }))
    Object.assign(payload['dynamic_fields'], {'coordinator_appendix_list': coordinatorAppendixList});

    let formData: any = new FormData();
    formData.append('report', new Blob([JSON.stringify(payload)], {type: "application/json"}));
    formData.append('files', new Blob([JSON.stringify(application.files.concat(application.coordinatorFiles).map(file => file.id))], { type: 'application/json; charset=UTF-8' }));

    return application.uuid
      ? this.httpClient.put('/reporting/api/latest/reports', formData)
      : this.httpClient.post('/reporting/api/latest/reports', formData)
  }

  sendApplication(application: Application) {
    let payload = {
      'status': 'submitted_to_coordinator',
      'report_id': application.uuid,
      'dynamic_fields': {
        'applicant_username': application.applicantUserName
      }
    }

    let formData: any = new FormData();
    formData.append('report', new Blob([JSON.stringify(payload)], {type: "application/json"}));

    return this.httpClient.patch('/reporting/api/latest/reports', formData); 
  }

  sendApplicationBackToApplicant(application: Application) {
    let payload = {
      'status': 'returned_to_applicant',
      'report_id': application.uuid
    }

    let formData: any = new FormData();
    formData.append('report', new Blob([JSON.stringify(payload)], {type: "application/json"}));

    return this.httpClient.patch('/reporting/api/latest/reports', formData);
  }

  sendApplicationToAgency(application: Application) {
    let payload = {
      'status': 'submitted_to_agency',
      'report_id': application.uuid
    }

    let formData: any = new FormData();
    formData.append('report', new Blob([JSON.stringify(payload)], {type: "application/json"}));

    return this.httpClient.patch('/reporting/api/latest/reports', formData);
  }

  rejectApplication(application: Application): Observable<any> {
    let payload = {
      'status': 'rejected',
      'report_id': application.uuid
    }

    let formData: any = new FormData();
    formData.append('report', new Blob([JSON.stringify(payload)], {type: "application/json"}));

    return this.httpClient.patch('/reporting/api/latest/reports', formData);
  }

  uploadFile(file: File | null): Observable<[string, string]> {
    if (file) {
      let formData: any = new FormData();
      formData.append('file', file, file.name);

      return this.httpClient
        .post(`/reporting/api/latest/attachments/upload/${file.name}/${file.size}`, formData)
        .pipe(
          map(id => [file.name, id + ''])
        );
    }

    return EMPTY;
  }
}
